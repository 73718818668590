import Spinner from "@/common/pages/spinner";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

const Home = () => {
  const { data: session }: any = useSession();
  const router = useRouter();
  if (session.user.role === "ADMIN") {
    router.push("/admin");
  } else {
    router.push("/organization");
  }
  return <Spinner />;
};

Home.auth = { role: "ANY" };
export default Home;
